import Vue from 'vue';

export default {
  getCategories: async (type) => Vue.http.get(`general/artists/categories/${type}`)
    .then((res) => res.json()),

  get: async (type, page, limit) => Vue.http.get(`general/artists/list/${type}${page ? `?page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`)
    .then((res) => res.json()),

  getById: async (id) => Vue.http.get(`general/artists/${id}`)
    .then((res) => res.json()),

  getVideos: async (id) => Vue.http.get(`general/artists/videos/${id}`)
    .then((res) => res.json()),

  getGallery: async (id) => Vue.http.get(`general/artists/gallery/${id}`)
    .then((res) => res.json()),

  getMarketplace: async (id) => Vue.http.get(`general/artists/marketplace/${id}`)
    .then((res) => res.json()),

  update: async (model, id) => Vue.http.put(`general/artists/${id}`, model)
    .then((res) => res.json()),

  updateTags: async (model, id) => Vue.http.put(`general/artists/${id}/tags/`, model)
    .then((res) => res.json()),

  uploadPicture: async (model, id, type) => Vue.http.post(`general/artists/${id}/upload/${type}/`, model)
    .then((res) => res.json()),

  reorderImage: async (model, id) => Vue.http.post(`general/artists/${id}/images/reorder/`, model)
    .then((res) => res.json()),

  deleteImage: async (imageId, id) => Vue.http.delete(`general/artists/${id}/images/${imageId}/`)
    .then((res) => res.json()),

  insertImage: async (model, id) => Vue.http.post(`general/artists/${id}/images/`, model)
    .then((res) => res.json()),

  updateImage: async (model, id) => Vue.http.put(`general/artists/${id}/images/${model.id}`, model)
    .then((res) => res.json()),

  save: async (model) => Vue.http.post('general/artists', model)
    .then((res) => res.json()),

  delete: async (id) => Vue.http.delete(`general/artists/${id}`)
    .then((res) => res.json()),

  insertVideo: async (model, id) => Vue.http.post(`general/artists/${id}/videos/`, model)
    .then((res) => res.json()),

  updateVideo: async (model, id) => Vue.http.put(`general/artists/${id}/videos/${model.id}`, model)
    .then((res) => res.json()),

  deleteVideo: async (videoId, id) => Vue.http.delete(`general/artists/${id}/videos/${videoId}/`)
    .then((res) => res.json()),

  reorderVideo: async (model, id) => Vue.http.post(`general/artists/${id}/videos/reorder/`, model)
    .then((res) => res.json()),

  insertProducts: async (model, id) => Vue.http.post(`general/artists/${id}/products/`, model)
    .then((res) => res.json()),

  updateProducts: async (model, id) => Vue.http.put(`general/artists/${id}/products/${model.id}`, model)
    .then((res) => res.json()),

  deleteProducts: async (productId, id) => Vue.http.delete(`general/artists/${id}/products/${productId}/`)
    .then((res) => res.json()),

  reorderProducts: async (model, id) => Vue.http.post(`general/artists/${id}/products/reorder/`, model)
    .then((res) => res.json()),
};
