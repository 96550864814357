<template>
  <div>
    <b-row class="my-4">
      <b-col cols="8">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.' + type) }}
        </h3>

        <router-link :to="{ name: 'general-' + type + '-create' }">
          <b-button size="sm" class="ml-3" variant="success">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-plus-circle-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5
                4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5
                0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
              />
            </svg>
            {{ $t('buttons.add') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col lg="6" class="mb-sm-2">
        <b-input-group>
          <b-form-input
            v-model="filter"
            type="search"
            :placeholder="$t('inputs.typeSearch')"
          />

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              {{ $t('buttons.clear') }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col lg="2" sm="12">
        <b-form-select v-model="live" class="mr-2">
          <b-form-select-option :value="null">
            {{ $t('artistsManagement.filterLive') }}
          </b-form-select-option>
          <b-form-select-option value="true">
            {{ $t('general.yes') }}
          </b-form-select-option>
          <b-form-select-option value="false">
            {{ $t('general.no') }}
          </b-form-select-option>
        </b-form-select>
      </b-col>

      <b-col lg="2" sm="12">
        <b-form-select v-model="active" class="mr-2">
          <b-form-select-option :value="null">
            {{ $t('inputs.active') }}?
          </b-form-select-option>
          <b-form-select-option value="true">
            {{ $t('general.yes') }}
          </b-form-select-option>
          <b-form-select-option value="false">
            {{ $t('general.no') }}
          </b-form-select-option>
        </b-form-select>
      </b-col>

      <b-col lg="2" sm="12">
        <b-form-select v-model="agreement" class="mr-2">
          <b-form-select-option :value="null">
            {{ $t('artistsManagement.filterContract') }}
          </b-form-select-option>
          <b-form-select-option value="true">
            {{ $t('general.yes') }}
          </b-form-select-option>
          <b-form-select-option value="false">
            {{ $t('general.no') }}
          </b-form-select-option>
        </b-form-select>
      </b-col>

      <!-- <b-col lg="2" sm="12">
        <b-form-select v-model="approved" class="mr-2">
          <b-form-select-option :value="null">
            {{ $t('artistsManagement.approved') }}
          </b-form-select-option>
          <b-form-select-option value="true">
            {{ $t('general.yes') }}
          </b-form-select-option>
          <b-form-select-option value="false">
            {{ $t('general.no') }}
          </b-form-select-option>
        </b-form-select>
      </b-col> -->

      <b-col lg="4" sm="12" class="text-right">
        <p class="mb-0">
          {{ $t('artistsManagement.registers', { num: totalRows }) }}
        </p>
      </b-col>
    </b-row>

    <b-table
      show-empty
      responsive
      bordered
      striped
      hover
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :busy="loading"
      :items="data"
      :fields="fields"
      :emptyText="$t('messages.emptyTable')"
      :emptyFilteredText="$t('messages.emptyFilteredTable')"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div
          class="
            text-center text-danger
            my-2
            align-items-center
            d-flex
            flex-column
          "
        >
          <b-spinner
            class="align-middle flex-direction-column mb-3"
          ></b-spinner>
          <strong>{{ $t('messages.loading') }}...</strong>
        </div>
      </template>

      <template v-slot:cell(isActive)="data">
        <p class="text-center m-0">
          <b-icon
            v-if="data.value"
            icon="check-circle-fill"
            variant="success"
          />
          <b-icon v-else icon="x-circle-fill" variant="danger" />
        </p>
      </template>

      <template v-slot:cell(live)="data">
        <p class="text-center m-0">
          <b-icon
            v-if="data.value"
            icon="check-circle-fill"
            variant="success"
          />
          <b-icon v-else icon="x-circle-fill" variant="danger" />
        </p>
      </template>

      <template v-slot:cell(filled)="data">
        <p class="text-center m-0">
          <b-icon
            v-if="data.value"
            icon="check-circle-fill"
            variant="success"
          />
          <b-icon v-else icon="x-circle-fill" variant="danger" />
        </p>
      </template>

      <template v-slot:cell(agreementSigned)="data">
        <p class="text-center m-0">
          <b-icon
            v-if="data.value"
            icon="check-circle-fill"
            variant="success"
          />
          <b-icon v-else icon="x-circle-fill" variant="danger" />
        </p>
      </template>

      <!-- <template v-slot:cell(needModeration)="data"> -->
      <!--   <p class="text-center m-0"> -->
      <!--     <b-icon -->
      <!--       v-if="data.value" -->
      <!--       icon="check-circle-fill" -->
      <!--       variant="success" -->
      <!--     /> -->
      <!--     <b-icon v-else icon="x-circle-fill" variant="danger" /> -->
      <!--   </p> -->
      <!-- </template> -->

      <template v-slot:cell(createdAt)="data">
        <p class="text-center m-0">
          {{ formatDate(data.value) }}
        </p>
      </template>

      <template v-slot:cell(actions)="data">
        <p class="text-center m-0">
          <b-button size="sm" variant="info" @click="onEdit(data)">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0
                0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
            {{ $t('buttons.edit') }}
          </b-button>

          <span class="mx-1" />
        </p>
      </template>
    </b-table>

    <b-row class="text-center mt-4" v-if="totalRows > perPage">
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="onChangedPage"
          align="center"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';
import ArtistsManage from '../../../../services/ArtistsManage';

export default {
  props: ['type'],

  watch: {
    $route() {
      this.onLoad();
    },

    // eslint-disable-next-line object-shorthand
    '$route.path'() {
      this.list = [];
      this.filter = '';
      this.active = null;
      this.live = null;
      this.agreement = null;
      this.approved = null;
      this.totalRows = 0;
      this.loading = true;
      this.currentPage = 1;
    },
  },

  data() {
    return {
      list: [],
      filter: '',
      active: null,
      live: null,
      agreement: null,
      approved: null,

      totalRows: 0,
      loading: true,
      currentPage: 1,
      perPage: 20,
    };
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      try {
        this.loading = true;
        this.currentPage = this.$route.query.page || 1;
        this.perPage = this.$route.query.limit || 20;

        const { data, totalCount } = await ArtistsManage.get(
          this.type,
          this.currentPage,
          this.perPage,
        );

        this.fillList(data);
        this.totalRows = totalCount;
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        this.loading = false;
      }
    },

    onFiltered() {
      this.currentPage = 1;
    },

    formatDate(dt) {
      try {
        return DateTime.fromISO(dt).setLocale(this.$i18n.locale).toFormat('ff');
      } catch (e) {
        return dt;
      }
    },

    onEdit({ item }) {
      window.open(`${process.env.VUE_APP_CMS_ARTISTS}/login?jwt=${item.jwt}`);
    },

    filterData() {
      return this.list.filter((item) => {
        if (this.live !== null && this.live !== item.live.toString()) return false;

        if (this.active !== null && this.active !== item.isActive.toString()) return false;

        if (
          this.agreement !== null
          && this.agreement !== item.agreementSigned.toString()
        ) return false;

        // if (
        //   this.approved !== null
        //   && this.approved !== item.isApproved.toString()
        // ) return false;

        return true;
      });
    },

    compareItems(firstItem, secondItem) {
      if (firstItem.isApproved === secondItem.isApproved) {
        return 0;
      }

      if (firstItem.isApproved) return 1;

      return -1;
    },

    fillList(artistList) {
      artistList.forEach((artist) => {
        const index = this.list.findIndex((item) => item.id === artist.id);
        if (index === -1) this.list.push(artist);
      });
    },

    onChangedPage() {
      // this.$router.push({
      //   name: 'general-artists',
      //   query: { page, limit: this.perPage },
      // });
    },
  },

  computed: {
    data() {
      const filteredList = this.filterData();
      const orderedList = this.approved === null
        ? filteredList.sort(this.compareItems)
        : filteredList;

      return orderedList;
    },

    fields() {
      return [
        { key: 'isActive', label: this.$t('inputs.active'), sortable: true },
        { key: 'live', label: this.$t('inputs.live'), sortable: true },
        { key: 'name', label: this.$t('inputs.name'), sortable: true },
        { key: 'filled', label: this.$t('inputs.filled'), sortable: false },
        {
          key: 'agreementSigned',
          label: this.$t('inputs.agreementSigned'),
          sortable: true,
        },
        // {
        //   key: 'needModeration',
        //   label: this.$t('inputs.needModeration'),
        //   sortable: true,
        // },
        { key: 'actions', label: this.$t('inputs.action') },
      ];
    },
  },
};
</script>

<style lang="sass" scoped></style>
